import React from 'react';
import {withRouter} from "../withRouter";
import {useTranslation} from 'react-i18next';
import build from "../../buildConfigs/build.json";

const Languages = (props) => {
    const {t, i18n} = useTranslation();
    let currentLang: string;

    const lngs = build.lang;

    const changeLanguage = (lang: any): void => {
        currentLang = lang;
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const quit = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('terms');
        props.navigate('/');
    }

    const isAuth = (): boolean => {
        let token = localStorage.getItem('access_token');
        let res = false;

        if(token && token !== "undefined") {
            res = true;
        }

        return res;
    }

    const isLangActive = (lang: string): string => {
        let res = '';

        let savedLang = localStorage.getItem('lang');

        if (savedLang) {
            currentLang = savedLang;
        } else {
            currentLang = 'ua';
        }

        if (lang === currentLang) {
            res = 'active'
        }

        return res;
    }

    const doSetup = () => {
        if(!props.settingClick) {
            return;
        }

        props.settingClick();
    }

    return (
        <div className={`languages ${props.centered ? 'jcc' : ''}`}>
            {(isAuth() && !props.hideExit) && <div onClick={() => quit()} className="language exit">{t('cabinet.logout')}</div>}

            {Object.keys(lngs).length > 1 && <div className={'languages__list'}>
                {Object.keys(lngs).map(lng => (
                    <div className={"language " + isLangActive(lng)} key={lng} onClick={() => {
                        changeLanguage(lng);
                    }}>{lngs[lng].nativeName}</div>
                ))}
            </div>}

            {isAuth() && window.innerWidth > 1250 && <div onClick={() => doSetup()} className="language icon-setup"></div>}
        </div>
    )
}


export default withRouter(Languages);