import React from 'react';
import {withRouter} from "../components/withRouter";
import {apiRequest} from "../components/Globals";
import Languages from "../components/form/Languages";
import {withTranslation} from "react-i18next";
import {Notify} from "../service/Notify";
import build from "../buildConfigs/build.json";


class TermsAndConditions extends React.Component<any, any> {
    state = {
        isAcceptTerms: null
    }

    isAccept: any = {};

    constructor(props) {
        super(props);

        build.ofertas.forEach((oferta, key) => {
            this.isAccept[key] = false;
        })

        this.toggleApprove = this.toggleApprove.bind(this);
    }

    checkAccepted() {
        let res = true;
        for (let key in this.isAccept) {
            if (!this.isAccept[key]) {
                res = this.isAccept[key];
            }
        }
        return res;
    }

    acceptOffer() {
        if (!this.checkAccepted()) {
            Notify('error', 'Need to confirm all terms');
            return;
        }

        apiRequest('/user/oferta')
            .then(data => {
                localStorage.setItem('terms', '1');
                this.props.navigate('/cabinet');

            }).catch(error => {
            console.error(error)
            Notify('error', error.message ? error.message : this.props.t('error.default'));
        })
    }

    toggleApprove(isAccept, key) {
        localStorage.setItem('terms', isAccept);
        this.isAccept[key] = isAccept;
        this.setState({});
    }


    render() {
        let t = this.props.t;

        return (
            <div className={'cabinet__main'}>
                <div className="cabinet">
                    <div className="container container--cabinet terms">
                        <Languages/>

                        <div className="terms__item">
                            <div className="cabinet__holder">
                                <h3 className="cabinet__title">{t('agreement.title')}</h3>
                            </div>

                            <section className="inner-box">
                                <div className="plan">
                                    <div className="ng-untouched ng-pristine ng-valid">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td style={{width: "50px"}}></td>
                                            </tr>
                                            {build.ofertas.map((oferta, key) => {
                                                return (
                                                    <tr className="c__form_approve0" key={key + Math.random()}>
                                                        <td className={'pb-2'}>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => this.toggleApprove(e.target.checked, key)}
                                                                name={`ck0${key}`}
                                                                checked={this.isAccept[key]}
                                                            />
                                                        </td>

                                                        <td className={'pb-2'}>
                                                            <label
                                                                onClick={() => this.toggleApprove(!this.isAccept[key], key)}
                                                                dangerouslySetInnerHTML={{__html: oferta === 'agreement.desc' ? t(oferta) : oferta}}>
                                                            </label>
                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                            <tr>
                                                <td className="split"></td>
                                                <td></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="terms__btn">
                                            <button onClick={() => this.acceptOffer()}
                                                    className="plan__btn user__button button">{t('agreement.acceptBtn')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withTranslation()(withRouter(TermsAndConditions));
