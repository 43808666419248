export class Slider_service {

  timer;
  activeSlide = 0;
  slides = [
    {
      title: 'slider.trackMovementOnline.title',
      content: 'slider.trackMovementOnline.content'
    },
    {
      title: 'slider.fuelControl.title',
      content: 'slider.fuelControl.content'
    },
    {
      title: 'slider.controlThePassageOfRoutes.title',
      content: 'slider.controlThePassageOfRoutes.content'
    },
    {
      title: 'slider.trackStaffActivity.title',
      content: 'slider.trackStaffActivity.content'
    },
    {
      title: 'slider.monitorOperation.title',
      content: 'slider.monitorOperation.content'
    },
    {
      title: 'slider.analyzeTheSensorData.title',
      content: 'slider.analyzeTheSensorData.content'
    },
    {
      title: 'slider.controlTheUseOfEquipment.title',
      content: 'slider.controlTheUseOfEquipment.content'
    }
  ];

  nextSlide() {
    if (this.activeSlide >= this.slides.length - 1) {
      this.activeSlide = 0;
    } else {
      this.activeSlide++;
    }
  }

  newTimer(set) {
    this.clearTimer();
    this.timer = setTimeout(() => {
      this.nextSlide();
      this.newTimer(set);
      set()
    }, 10000);
  }

  chooseSlide(index, refresh: Function) {
    this.activeSlide = index;
    this.clearTimer();
    this.newTimer(null);
    refresh();
  }

  clearTimer() {
    clearInterval(this.timer);
  }

}
