import {LoginModel} from "../../model/LoginModel";
import React from "react";
import TextField from "../../components/form/TextField";
import {apiRequest} from "../../components/Globals";
import {Notify} from "../../service/Notify";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../components/withRouter";

class NewPasswordStep extends React.Component<{
    loginForm: LoginModel,
    t: Function,
    navigate: Function,
    refresh: Function,
    withSubmit: boolean
}> {

    state = {
        pending: false
    }

    constructor(props) {
        super(props);

        this.changePassword = this.changePassword.bind(this);
        this.getPassError = this.getPassError.bind(this);
    }

    changePassword() {
        if (!this.props.loginForm.isValid('changePassword')
            || this.props.loginForm.password !== this.props.loginForm.confirmPassword) {

            if (this.props.refresh) {
                this.props.refresh();
            }
            return;
        }
        this.setState({pending: true});
        let payload = this.props.loginForm.resetPasswordPayload();

        apiRequest('/user/reset', payload)
            .then((response: { message: string }) => {
                Notify('success', response.message ?? this.props.t('notification.changed_pas'));
                this.props.navigate('/');

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));
            this.setState({pending: false});
        })
    }

    getPassError() {
        const loginForm = this.props.loginForm;

        let err = '';
        if (loginForm &&
            (loginForm.getFieldError('password') || loginForm.getFieldError('confirmPassword'))) {

            if (loginForm.password !== loginForm.confirmPassword) {
                err = this.props.t('error.password');
            } else {
                err = this.props.t('error.weakPass');
            }
        }
        return err;
    }

    render() {
        let t: Function = this.props.t;
        let loginForm = this.props.loginForm;

        return (
            <div onKeyDown={(e) => {
                if (e.key !== 'Enter') {
                    return;
                }
                this.changePassword();
            }}>
                <form
                    autoComplete={'off'}
                    onSubmit={e => e.preventDefault()}
                >
                <div className="input__holder">

                    <TextField
                        label={t('login.password')}
                        type="password"
                        tooltip={t('notification.password')}
                        onChange={(e) => {
                            loginForm.password = e.target.value;
                            loginForm.isValid('changePassword');
                            this.setState({});
                        }}
                        name={'password'}
                        errorMessage={this.getPassError()}
                    />
                </div>
                <div className="input__holder">
                    <TextField
                        label={t('password.repeatPas')}
                        type="password"
                        onChange={(e) => {
                            loginForm.confirmPassword = e.target.value;
                            loginForm.isValid('changePassword');
                            this.setState({});
                        }}
                        name={'repeatPassword'}
                        errorMessage={this.getPassError()}
                    />
                </div>
                </form>

                {this.props.withSubmit && <button disabled={this.state.pending} onClick={this.changePassword} className="login__btn">
                    {t('button.submitPas')}
                </button>}
            </div>);
    }
}

export default withTranslation()(withRouter(NewPasswordStep));
