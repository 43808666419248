import {LoginModel} from "../../model/LoginModel";
import React from "react";
import TextField from "../../components/form/TextField";
import {withTranslation} from "react-i18next";

class EnterCodeStep extends React.Component<{
    loginForm: LoginModel,
    setTimer: Function,
    t: Function,
    getCode: Function,
    goToGetCodeStep: Function,
    clearTimer: Function,
    part: string,
    sendTimer: number,
}> {
    state = {};

    render() {
        let t: Function = this.props.t;
        let loginForm = this.props.loginForm;

        return (
            <>
                <div className="phone-content">
                    {loginForm.phone}
                    <div className="link"
                         onClick={() => {
                             this.props.clearTimer();
                             this.props.goToGetCodeStep();
                         }}>
                        {t('password.change')}
                    </div>

                    <button className={`link numbers ${this.props.sendTimer && 'disabled'}`}
                            onClick={() => this.props.getCode()}
                            disabled={!!this.props.sendTimer}>{t('password.repeat')}&nbsp;
                        {this.props.sendTimer &&
                            <span className={`login__timer_${this.props.part}`}>{this.props.sendTimer}c</span>}
                    </button>
                </div>

                <div onKeyDown={(e) => {
                    if (e.key !== 'Enter') {
                        return;
                    }

                }} className="input__holder">
                    <TextField
                        label={t('password.code')}
                        onChange={(e) => loginForm.code = e.target.value}
                        name={'code'}
                        errorMessage={loginForm.getFieldError('code') && t('error.required')}
                    />
                </div>
            </>);
    }
}

export default withTranslation()(EnterCodeStep);
