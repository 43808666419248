import React from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "../components/withRouter";
import TextField from "../components/form/TextField";
import {LoginModel} from "../model/LoginModel";
import Languages from "../components/form/Languages";
import {apiRequest} from "../components/Globals";
import {Notify} from "../service/Notify";
import EnterCodeStep from "./forgotPassword/EnterCodeStep";
import NewPasswordStep from "./forgotPassword/NewPasswordStep";

class Register extends React.Component<{
    t: Function,
    navigate: Function
}> {
    state = {
        stage: 'getCode',
    }
    getTimer: { expirationTime: string, phone: string } = JSON.parse(localStorage.getItem('regTimerLeft'));
    loginForm: LoginModel;

    sendTimer: number = null;
    intervalTimer;
    acceptTermsCb: boolean = true;


    constructor(props) {
        super(props);
        this.loginForm = new LoginModel();

        this.backwardTimer = this.backwardTimer.bind(this);
        this.clearTimer = this.clearTimer.bind(this);
        this.sendRegCode = this.sendRegCode.bind(this);
        this.newSignUp = this.newSignUp.bind(this);

        this.saveLeftSmsSendTimer();
    }

    saveLeftSmsSendTimer() {
        if (this.getTimer && parseFloat(this.getTimer.expirationTime) > new Date().getTime()) {
            this.state.stage = 'enterCodeAndUserData';
            const diffInMilisec = Math.abs(parseFloat(this.getTimer.expirationTime) - new Date().getTime());
            const diffInSeconds: any = diffInMilisec / 1000;
            this.sendTimer = diffInSeconds;

            this.loginForm.regPhone = this.getTimer.phone;

            if (this.sendTimer && !this.intervalTimer) {
                this.setTimer(this.sendTimer.toString());
            }
        } else {
            localStorage.removeItem('regTimerLeft');
        }
    }

    sendRegCode(repeat: boolean) {
        if (!this.loginForm.isValid('reg') && !repeat) {
            this.setState({});
            return;
        }
        if (this.getTimer && this.getTimer.phone === this.loginForm.regPhone
            && parseFloat(this.getTimer.expirationTime) > new Date().getTime()) {

            Notify('error', this.props.t('notification.send_sms'));
            return;
        }

        let payload = {
            phone: this.loginForm.regPhone
        }

        apiRequest('/user/get-registration-code', payload)
            .then((data: any) => {
                if (!data) {
                    return;
                }
                localStorage.setItem('regTimerLeft', JSON.stringify({
                    phone: payload.phone,
                    expirationTime: String(new Date().getTime() + 10 * 60 * 1000)
                }));

                this.setTimer('600');

                this.setState({stage: 'enterCodeAndUserData'});

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));

            if (error.timerInSec) {
                this.setTimer(error.data.timerInSec);
            }
        })
    }

    newSignUp() {
        if (!this.loginForm.isValid('newSignUp')) {
            this.setState({});
            return;
        }

        let payload = this.loginForm.getSignUpPayload();

        apiRequest('/user/registration', payload)
            .then(data => {
                this.props.navigate('/');
                Notify('success', this.props.t('register.success'));

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));

        })
    }

    setTimer(time: string) {
        if (time) {
            this.sendTimer = parseInt(time);
            this.intervalTimer = setInterval(this.backwardTimer, 1000);
            this.setState({});
        }
    }

    backwardTimer() {
        let leftTimer = document.querySelector('.login__timer_register');

        --this.sendTimer;

        if (this.sendTimer === 1) {
            clearInterval(this.intervalTimer);
            this.sendTimer = null;
            this.intervalTimer = null;
            localStorage.removeItem('regTimerLeft');
            return this.setState({});
        }
        if (leftTimer) {
            leftTimer.innerHTML = this.sendTimer.toString() + 'c';
        } else if (this.intervalTimer){
            clearInterval(this.intervalTimer);
        }
    }

    clearTimer() {
        this.sendTimer = null;
        clearInterval(this.intervalTimer);
    }

    render() {
        let t: Function = this.props.t;

        return (
            <div className="login__wrap">
                <div className="login__item--data login__item--block">
                    <div className="login__block">
                        <button onClick={() => this.props.navigate('/')} className="login__back"></button>
                        <h3 className="login__subtitle">{t('button.register')}</h3>
                    </div>

                    <div className="login__languages languages">
                        <Languages refresh={() => this.setState({})}/>
                    </div>

                    <div className="login__form ng-untouched ng-pristine ng-valid">

                        {this.state.stage === 'getCode' && <>
                            <div onKeyDown={(e) => {
                                if (e.key !== 'Enter') {
                                    return;
                                }
                                this.sendRegCode(false);

                            }} className="input__holder">
                                <TextField
                                    label={t('login.phone')}
                                    onChange={(e) => this.loginForm.regPhone = e.target.value}
                                    name={'regPhone'}
                                    value={this.loginForm.regPhone}
                                    errorMessage={this.loginForm.getFieldError('regPhone') && t('error.required')}
                                />
                            </div>

                            <button onClick={_ => this.sendRegCode(false)} className="login__btn"
                                    disabled={!!this.sendTimer}>
                                {t('button.getCode')} {this.sendTimer &&
                                <span className={'login__timer timer_register'}>{this.sendTimer}c</span>}
                            </button>
                        </>}

                        {this.state.stage === 'enterCodeAndUserData' &&
                            <div onKeyDown={(e) => {
                                if (e.key !== 'Enter') {
                                    return;
                                }
                                this.newSignUp();
                            }}>
                                <EnterCodeStep
                                    part={'register'}
                                    loginForm={this.loginForm}
                                    setTimer={(time) => this.setTimer(time)}
                                    getCode={() => this.sendRegCode(true)}
                                    sendTimer={this.sendTimer}
                                    clearTimer={() => this.clearTimer()}
                                    goToGetCodeStep={() => this.setState({stage: 'getCode'})}
                                />

                                <NewPasswordStep
                                    loginForm={this.loginForm}
                                                 refresh={() => this.setState({})}
                                    withSubmit={false}
                                />

                                <div className="input__holder">
                                    <TextField
                                        label={t('register.country')}
                                        onChange={(e) => this.loginForm.country = e.target.value}
                                        name={'country'}
                                        value={this.loginForm.country}
                                        errorMessage={this.loginForm.getFieldError('country') && t('error.required')}
                                    />
                                </div>

                                <div className="input__holder">
                                    <TextField
                                        label={t('register.firstName')}
                                        onChange={(e) => this.loginForm.firstName = e.target.value}
                                        name={'firstName'}
                                        value={this.loginForm.firstName}
                                        errorMessage={this.loginForm.getFieldError('firstName') && t('error.required')}
                                    />
                                </div>

                                <div className="input__holder">
                                    <TextField
                                        label={t('register.lastName')}
                                        onChange={(e) => this.loginForm.lastName = e.target.value}
                                        name={'lastName'}
                                        value={this.loginForm.lastName}
                                        errorMessage={this.loginForm.getFieldError('lastName') && t('error.required')}
                                    />
                                </div>

                                <div className="input__holder">
                                    <TextField
                                        label={t('register.email')}
                                        onChange={(e) => this.loginForm.email = e.target.value}
                                        name={'email'}
                                        value={this.loginForm.email}
                                        errorMessage={this.loginForm.getFieldError('email') && t('error.required')}
                                    />
                                </div>
                                <button onClick={() => this.newSignUp()}
                                        className="login__btn">{t('cabinet.confirm')}</button>

                                <div style={{paddingTop: "20px"}}>
                                    <div className="flexed-box mb-1">
                                        <div className="check-box">
                                            <div className={`checkbox ${this.loginForm.acceptTermsCb ? 'checked' : ''}`}
                                                 onClick={() => {
                                                     this.loginForm.acceptTermsCb = !this.loginForm.acceptTermsCb;
                                                     this.setState({});
                                                 }}
                                            >
                                            </div>
                                        </div>
                                        <div className="placeholder-box">
                                            <div className="placeholder-text">
                                                        <span
                                                            dangerouslySetInnerHTML={{__html: t('register.approve')}}
                                                        ></span>
                                            </div>
                                        </div>

                                    </div>
                                    {this.loginForm.getFieldError('acceptTermsCb') &&
                                        <span className="required-error">
                                            {t('error.terms')}
                                        </span>}
                                </div>

                            </div>}

                        <div className="login__inner">
                            <p className="login__box login__box--question">{t('register.have')}</p>
                            <button onClick={() => this.props.navigate('/')} className="login__box login__box--answer">
                                {t('register.sign')}
                            </button>

                            <button onClick={() => this.props.navigate('/password')}
                                    className="login__box login__box--answer">{t('password.title')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Register));
