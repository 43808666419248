import {BaseValidator} from "./BaseValidator";

export class RegExpValidator extends BaseValidator {
    regExp: RegExp;

    constructor(regExp: RegExp, message: string = '') {
        super(message);
        this.regExp = regExp;
    }

    isValid(value: any): boolean {
        return this.regExp.test(value);
    }

}