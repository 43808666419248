import React from "react";
import Languages from "../../components/form/Languages";
import TextField from "../../components/form/TextField";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../components/withRouter";
import {LoginModel} from "../../model/LoginModel";
import {Slider_service} from "../../service/Slider_service";
import {apiRequest} from "../../components/Globals";
import {Notify} from "../../service/Notify";
import EnterCodeStep from "./EnterCodeStep";
import NewPasswordStep from "./NewPasswordStep";

class ForgotPassword extends React.Component<{
    t: Function,
    navigate: Function
}> {

    state = {
        stage: 'getCode',
    }

    getTimer: { expirationTime: string, phone: string } = JSON.parse(localStorage.getItem('forgotTimerLeft'));

    loginForm: LoginModel;
    sliderService: Slider_service;

    sendTimer: number = null;
    intervalTimer;

    constructor(props) {
        super(props);

        this.loginForm = new LoginModel();
        this.sliderService = new Slider_service();

        this.getCode = this.getCode.bind(this);
        this.backwardTimer = this.backwardTimer.bind(this);
        this.clearTimer = this.clearTimer.bind(this);

        this.saveLeftSmsSendTimer();
    }

    componentDidMount() {
        this.sliderService.newTimer(() => this.setState({}));
    }

    saveLeftSmsSendTimer() {
        if (this.getTimer && parseFloat(this.getTimer.expirationTime) > new Date().getTime()) {
            this.state.stage = 'enterCodeNewPass';
            const diffInMilisec = Math.abs(parseFloat(this.getTimer.expirationTime) - new Date().getTime());
            const diffInSeconds: any = diffInMilisec / 1000;
            this.sendTimer = diffInSeconds;

            this.loginForm.forgotPhone = this.getTimer.phone;

            if (this.sendTimer && !this.intervalTimer) {
                this.setTimer(this.sendTimer.toString());
            }
        } else {
            localStorage.removeItem('forgotTimerLeft');
        }
    }

    backwardTimer() {
        let leftTimer = document.querySelector('.login__timer_forgot');

        --this.sendTimer;

        if (this.sendTimer === 1) {
            clearInterval(this.intervalTimer);
            this.sendTimer = null;
            this.intervalTimer = null;
            localStorage.removeItem('forgotTimerLeft');
            return this.setState({});
        }

        if (leftTimer) {
            leftTimer.innerHTML = this.sendTimer.toString() + 'c';
        } else if (this.intervalTimer){
            clearInterval(this.intervalTimer);
        }
    }

    setTimer(time: string) {
        if (time) {
            this.sendTimer = parseInt(time);
            this.intervalTimer = setInterval(this.backwardTimer, 1000);
            this.setState({});
        }
    }

    clearTimer() {
        this.sendTimer = null;
        clearInterval(this.intervalTimer);
    }

    getCode() {
        if (!this.loginForm.isValid('forgot')) {
            return this.setState({});
        }

        if (this.getTimer && this.getTimer.phone === this.loginForm.regPhone
            && parseFloat(this.getTimer.expirationTime) > new Date().getTime()) {

            Notify('error', this.props.t('notification.send_sms'));
            return;
        }

        let payload = {
            phone: this.loginForm.forgotPhone
        }

        apiRequest('/user/get-reset-code', payload)
            .then((data: any) => {

                localStorage.setItem('forgotTimerLeft', JSON.stringify({
                    phone: payload.phone,
                    expirationTime: String(new Date().getTime() + 10 * 60 * 1000)
                }));

                this.setTimer('600');

                this.setState({stage: 'enterCodeNewPass'});

            }).catch(error => {
            console.error(error);
            Notify('error', error.message ? error.message : this.props.t('error.default'));

            this.setTimer(error.timerInSec);
        })
    }

    render() {
        let t: Function = this.props.t;
        let slider = this.sliderService;

        return (
            <div className="login">
                <div className="container">
                    <div className="container container--inner">
                        <div className="login__wrap">

                            <div className="login__item login__item--content">
                                <div className="login__slider"
                                     onMouseEnter={() => slider.clearTimer()}
                                     onMouseLeave={() => slider.newTimer(() => this.setState({}))}
                                >
                                    <div className="login__box">
                                        <h3 className="login__title">
                                            {t(slider.slides[slider.activeSlide].title)}
                                        </h3>
                                        <div className="login__text">
                                            <p>{t(slider.slides[slider.activeSlide].content)}</p>
                                        </div>
                                    </div>
                                    <div className="login__dots">
                                        {this.sliderService.slides.map((slide, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={() => slider.chooseSlide(i, () => this.setState({}))}
                                                    className={`login__dot ${i === slider.activeSlide ? ' login__dot--active' : ""}`}>
                                                    <span></span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="login__item login__item--data">
                                <div className="login__subtitle--in">
                                    <button onClick={() => this.props.navigate('/')} className="login__back"></button>
                                </div>

                                <h3 className="login__subtitle login__subtitle--in">
                                    {t('password.title')}
                                </h3>

                                <div className="login__languages languages">

                                    <Languages refresh={() => this.setState({})}/>

                                </div>
                                <div className="login__form">

                                    {this.state.stage === 'getCode' && <>
                                        <div onKeyDown={(e) => {
                                            if (e.key !== 'Enter') {
                                                return;
                                            }
                                            this.getCode();

                                        }} className="input__holder">
                                            <TextField
                                                label={t('login.phone')}
                                                onChange={(e) => this.loginForm.forgotPhone = e.target.value}
                                                name={'forgotPhone'}
                                                errorMessage={this.loginForm.getFieldError('forgotPhone') && t('error.required')}
                                            />
                                        </div>

                                        <button onClick={_ => this.getCode()}
                                                className="login__btn"
                                                disabled={!!this.sendTimer}>
                                            {t('button.getCode')} {this.sendTimer &&
                                            <span id={'timer_forgot'} className={'login__timer'}>{this.sendTimer}c</span>}
                                        </button>
                                    </>}


                                    {this.state.stage === 'enterCodeNewPass' &&
                                        <>
                                            <EnterCodeStep
                                                part={'forgot'}
                                                loginForm={this.loginForm}
                                                setTimer={(time) => this.setTimer(time)}
                                                getCode={() => this.getCode()}
                                                sendTimer={this.sendTimer}
                                                clearTimer={() => this.clearTimer()}
                                                goToGetCodeStep={() => this.setState({stage: 'getCode'})}
                                            />
                                            <NewPasswordStep
                                                withSubmit={true}
                                                loginForm={this.loginForm}
                                                refresh={() => this.setState({})}/>
                                        </>
                                    }

                                    <div className="login__inner">
                                        <p className="login__box login__box--question">
                                            {t('register.have')}
                                        </p>

                                        <button onClick={() => this.props.navigate('/')}
                                                className="login__box login__box--answer"> {t('button.login')}
                                        </button>

                                        <button onClick={() => this.props.navigate('/register')}
                                                className="login__box login__box--answer">{t('login.register')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

export default withTranslation()(withRouter(ForgotPassword));
