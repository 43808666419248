import {Auth} from "./Auth";
import ConfigService from "../service/ConfigService";

export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
};

/**
 * Main API request
 *
 * @param url
 * @param method
 * @param data
 * @returns {Promise<unknown>}
 */
export function apiRequest(url: string, data?: any, method: string = null) {
    let readPromise = new Promise((resolve, reject) => {
        method = method ? method.toUpperCase() : 'POST';
        let auth = new Auth();
        let configService = new ConfigService();
        url += `?${configService.data.phpSuffix}`;

        auth.load();

        let init = {
            method: method,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': auth.token ? 'Bearer ' + auth.token : configService.data.basikToken,
            },
            body: null
        };


        if (data) {
            if (method === 'POST') {
                const replacer = (key, value) =>
                    typeof value === 'undefined' ? null : value;

                init.body = JSON.stringify(data, replacer);


            } else {
                let addUrl = '';
                for (const [key, val] of Object.entries(data)) {
                    addUrl += key + '=' + val + '&';
                }

                if (addUrl.length > 0) {
                    if (url.indexOf('?') !== -1) {
                        url += '&';
                    } else {
                        url += '?';
                    }

                    url += addUrl.substr(0, addUrl.length - 1);
                }
            }
        }

        let apiUrl = configService.data.phpApi;

        fetch(apiUrl + url, init)
            .then(response => {
                // response only can be ok in range of 2XX
                if (response.ok) {
                    // you can call response.json() here too if you want to return json
                    let data = response.json();
                    resolve(data);
                } else {
                    //handle errors in the way you want to
                    switch (response.status) {
                        case 401:
                            // browserHistory.push("/not-found");

                            let auth = new Auth();
                            auth.save();
                            document.location.reload();

                            return;
                        case 404:
                            // browserHistory.push("/not-found");
                            console.log('Object not found');
                            return;
                        case 500:
                            console.log('Internal server error');
                            console.error(response);
                            //return response error object from server
                            return response.json().then(err => {
                                throw err
                            });

                        default:
                            console.log('Some error occured');
                            break;
                    }

                    //here you also can thorow custom error too
                    reject(response);
                }
            })
            .catch(error => {
                //it will be invoked mostly for network errors
                //do what ever you want to do with error here
                console.log(['Here: ', error]);
                reject(error);
            });
    });

    return new Promise((resolve, reject) => {
        readPromise.then((data: { data: {} }) => {
            if (data) {
                resolve(data.data);
            }

        }).catch(error => {
            console.log(error);
            reject(error);
        })
    });
}
