import {RegExpValidator} from "./RegExpValidator";

export class EmailValidator extends RegExpValidator {


    constructor(regExp: RegExp = null, message: string = '') {
        let mailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        super(mailRegExp, message);
    }

}