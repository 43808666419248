import alertify from 'alertifyjs';

export const Notify = (errorType, message) => {
    let wait = 5;
    let type = 'custom';

    alertify.set('notifier', 'position', 'bottom-right');
    alertify.set('notifier', 'delay', wait);

    /**
     * To show notify types:
     *
     * notify
     * success
     * error
     * warning
     */

    return alertify[errorType](message, type, wait);
}
