import React, {Component} from 'react';
import {LoginModel} from "../model/LoginModel";
import {Auth} from "../components/Auth";
import TextField from "../components/form/TextField";
import {withRouter} from "../components/withRouter";
import Languages from "../components/form/Languages";
import {withTranslation} from "react-i18next";
import {Slider_service} from "../service/Slider_service";
import {Notify} from "../service/Notify";
import {apiRequest} from "../components/Globals";
import build from "../buildConfigs/build.json";

class Login extends Component<any, any> {
    state = {
        part: 'login',
        slide: 0,
        pending: false
    };
    auth: Auth;
    loginForm: LoginModel;
    key: number = null;
    userId: number = null;

    sliderService: Slider_service;

    constructor(props) {
        super(props);

        this.auth = new Auth();
        this.loginForm = new LoginModel();
        this.sliderService = new Slider_service();

        this.doLogin = this.doLogin.bind(this);
        // this.doStartReset = this.doStartReset.bind(this);
        // this.setNewUserPass = this.setNewUserPass.bind(this);
        // this.checkResetCode = this.checkResetCode.bind(this);

    }

    componentDidMount() {
        this.sliderService.newTimer(() => this.setState({}));
    }

    doLogin() {
        if (!this.loginForm.isValid('login')) {
            this.setState({});

            return;
        }
        this.setState({pending: true});

        let payload = {
            phone: this.loginForm.phone,
            password: this.loginForm.password
        }

        apiRequest('/user/login', payload)
            .then((data: any) => {
                if (!data) {
                    console.error('login_data', data);
                    return;
                }
                this.auth.assign(data);
                this.auth.save();
                this.props.isAuth();
                this.props.navigate('/cabinet');

            })
            .catch(error => {
                this.loginForm.password = '';
                this.loginForm.isValid('login');
                console.error(error);
                Notify('error', error.message ?? this.props.t('error.invalid_pass'));
                this.setState({pending: false});
            })
    }

    render() {
        let t: Function = this.props.t;
        let slider = this.sliderService;

        return (
            <div className="login">

                <div className="container">
                    <div className="container container--inner">
                        <div className="login__wrap">

                            <div className="login__item login__item--content">
                                <div className="login__slider"
                                     onMouseEnter={() => slider.clearTimer()}
                                     onMouseLeave={() => slider.newTimer(() => this.setState({}))}
                                >
                                    <div className="login__box">
                                        <h3 className="login__title">
                                            {t(slider.slides[slider.activeSlide].title)}
                                        </h3>
                                        <div className="login__text">
                                            <p>{t(slider.slides[slider.activeSlide].content)}</p>
                                        </div>
                                    </div>
                                    <div className="login__dots">
                                        {this.sliderService.slides.map((slide, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    onClick={() => slider.chooseSlide(i, () => this.setState({}))}
                                                    className={`login__dot ${i === slider.activeSlide ? ' login__dot--active' : ""}`}>
                                                    <span></span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div onKeyDown={(e) => {
                                if (e.key !== 'Enter') {
                                    return;
                                }
                                this.doLogin();

                            }} className="login__item login__item--data">
                                <h3 className="login__subtitle login__subtitle--in">
                                    {t('login.title')}
                                </h3>

                                <div className="login__languages languages">

                                    <Languages refresh={() => this.setState({})}/>

                                </div>
                                <div className="login__form">

                                    <div className="input__holder">
                                        <TextField
                                            label={t('login.phone')}
                                            onChange={(e) => this.loginForm.phone = e.target.value}
                                            name={'phone'}
                                            onFocus={() => slider.clearTimer()}
                                            onBlur={() => slider.newTimer(() => this.setState({}))}
                                            value={this.loginForm.phone}
                                            errorMessage={this.loginForm.getFieldError('phone') && t('error.required')}
                                        />
                                    </div>

                                    <div className="input__holder">
                                        <TextField
                                            label={t('login.password')}
                                            type="password"
                                            onFocus={() => slider.clearTimer()}
                                            onBlur={() => slider.newTimer(() => this.setState({}))}
                                            onChange={(e) => this.loginForm.password = e.target.value}
                                            name={'password'}
                                            value={this.loginForm.password}
                                            errorMessage={this.loginForm.getFieldError('password') && t('error.invalid_pass')}
                                        />
                                    </div>

                                    <button disabled={this.state.pending} onClick={this.doLogin} className="login__btn">
                                        {t('button.login')}
                                    </button>
                                    <div className="login__inner">
                                        <p className="login__box login__box--question">
                                            {t('login.forgot')}
                                        </p>

                                        <button onClick={() => this.props.navigate('/password')}
                                                className="login__box login__box--answer"> {t('login.remember')}
                                        </button>

                                        <button onClick={() => this.props.navigate('/register')}
                                                className="login__box login__box--answer">{t('login.register')}
                                        </button>
                                    </div>

                                    <div className="login__inner eu_links">
                                        {build && build.login_refs.map(ref => {
                                            return (
                                                <a target={"_blank"}
                                                   href={ref.link}
                                                   className="login__box login__box--answer">{ref.caption}
                                                </a>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Login));