import {useParams , useNavigate} from 'react-router-dom';

export const withRouter = (Component) => {

    const Wrapper = (props) => {
        let useParams1 = useParams();
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                useParams={useParams1}
                {...props}
            />
        );
    };

    return Wrapper;
};