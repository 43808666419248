import {BaseModel} from "./BaseModel";
import {NotEmptyValidator} from "../components/form/validators/NotEmptyValidator";
import {RegExpValidator} from "../components/form/validators/RegExpValidator";
import {EmailValidator} from "../components/form/validators/EmailValidator";
import {CallbackValidator} from "../components/form/validators/CallbackValidator";

export class LoginModel extends BaseModel {
    phone: string = null;
    password: string = null;
    regPhone: string = null;
    forgotPhone: string = null;
    code: string = null;
    confirmPassword: string = null;

    firstName: string = null;
    lastName: string = null;
    country: string = null;
    email: string = null;
    acceptTermsCb: boolean = false;

    constructor() {
        super();

        this.addValidator('phone', new NotEmptyValidator(), 'login')
            // .addValidator('phone', new RegExpValidator(/^([+]\d{10}|[+]\d{11})$/), 'login')
            .addValidator('password', new NotEmptyValidator(), 'login')
            // .addValidator('regPhone', new RegExpValidator(/^([+]\d{9}|[+]\d{11})$/), 'reg')
            .addValidator('regPhone', new NotEmptyValidator(), 'reg')
            // .addValidator('forgotPhone', new RegExpValidator(/^([+]\d{9}|[+]\d{11})$/), 'forgot')
            .addValidator('forgotPhone', new NotEmptyValidator(), 'forgot')
            .addValidator('password', new RegExpValidator(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/), 'changePassword')
            .addValidator('password', new CallbackValidator(() => this.checkPasses()), 'changePassword')
            .addValidator('confirmPassword', new CallbackValidator(() => this.checkPasses()), 'changePassword')
            .addValidator('confirmPassword', new RegExpValidator(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/), 'changePassword')
            .addValidator('code', new NotEmptyValidator(), 'changePassword')

            .addValidator('code', new NotEmptyValidator(), 'newSignUp')
            .addValidator('password', new RegExpValidator(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/), 'newSignUp')
            .addValidator('country', new NotEmptyValidator(), 'newSignUp')
            .addValidator('email', new EmailValidator(), 'newSignUp')
            .addValidator('firstName', new NotEmptyValidator(), 'newSignUp')
            .addValidator('lastName', new NotEmptyValidator(), 'newSignUp')
            .addValidator('acceptTermsCb', new CallbackValidator(() => this.isTermsAccepted()), 'newSignUp')

        ;

    }

    checkPasses() {
        return this.password === this.confirmPassword;
    }

    isTermsAccepted() {
        return this.acceptTermsCb === true;
    }

    resetPasswordPayload() {
        return {
            code: this.code,
            phone: this.forgotPhone,
            password: this.password
        }
    }

    getSignUpPayload() {
        return {
            code: this.code,
            phoneNumber: this.regPhone,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
            country: this.country,
            email: this.email,
        }
    }

}