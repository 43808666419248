import i18next from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import HttpBackend from "i18next-http-backend";
import build from '../buildConfigs/build.json';

let savedLang = localStorage.getItem('lang');

const getLang = () => {
    let lang = 'en';

    if (build.default_lang === 'ua') {
        if (savedLang) {
            lang = savedLang;
        } else {
            lang = 'ua';
        }
    }

    return lang;
}

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
            // debug: true,
            returnEmptyString: false,
            fallbackLng: getLang(),
            lng: getLang(),
            backend: {
                requestOptions: {
                    cache: 'no-store'
                },
                backends: [
                    HttpBackend
                ],
                backendOptions: [{
                    expirationTime: 100000,
                },
                    {
                        loadPath: `/locales/${savedLang}/translation.json?v=${Date.now()}`
                    }]
            }
        },
    )