import {BaseModel} from "../model/BaseModel";

export class Auth extends BaseModel {
    static storageKey = 'access_token';

    userId: number | string;
    name: string;
    token: string;


    load() {
        const data = localStorage.getItem(Auth.storageKey);

        if (!data) {
            return;
        }

        this.token = data;
    }

    getCurrentLang() {
        return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ua';
    }

    save() {
        localStorage.setItem(Auth.storageKey, this.token);
    }

    isAuthorized() {
        return this.token && this.token !== "undefined";
    }

    isSelf() {
        // return this.userId === this.adminId;
    }

    isInSession() {
        return sessionStorage.getItem('inSession') === '1';
    }

    setIsInSession(isInSession: boolean) {
        sessionStorage.setItem('inSession', isInSession ? '1' : '0');
    }

    setProperty(name: string, value: any): this {
        switch (name) {

            case "id":
                this.userId = value;
                break;

            default:
                super.setProperty(name, value);
        }

        return this;
    }
}
